<template>
  <div class="deploySetting-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData" class="common-noMarginLeft"></breadcrumb>
    <div class="set-title"> 咨询量设置</div>
    <div>
      店铺子账号每小时平均咨询量超过
      <div class="common-btn-input-container">
        <el-input
          class="common-btn-input-input"
          type="number"
          placeholder="请输入质检分数"
          v-model="score"
          min="0"
          @keyup.native="proving"
        ></el-input>
        <div class="common-btn-input-btn" @click="setWarningScore">确 认</div>
      </div>
      个，发送爆量预警通知
      <!-- <div class="setTip">
        通过分在1~100之间设置，若分数产生修改，不对历史数据产生作用
      </div> -->
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { setWarningScore, getWarningScore } from "../../service/service.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "爆量预警设置", isLink: false },
      ],
      score: "", // 爆量值
    };
  },
  created() {
    this.getWarningScore();
  },
  methods: {
    async getWarningScore() { // 获取爆量值
      let resData = (await getWarningScore()).data;
      this.score = resData;
    },
    async setWarningScore() { // 设置爆量值
      let score = this.score;
      if (score) {
        await setWarningScore({ score: this.score });
        this.$message.success("设置成功");
      } else {
        this.$message.error("请输入质检通过分数");
      }
    },
    proving() {
      this.score = this.score.replace(/[^\d]/g,'');
      this.score = this.score.replace('.','');
    },
  },
};
</script>
<style lang="less" scoped>
.deploySetting-container {
  text-align: left;
  .set-title {
    margin: 20px 0;
  }
  .setTip {
    display: inline-block;
    line-height: 32px;
    font-size: 14;
    color: #999;
    margin-left: 8px;
  }
}
</style>
