import fetch from "./xhr/index";

// /**
//  * 上传文件
//  * @param
//  */
// export const uploadUserXslx = param => fetch("upload", "/admin/uploadUserFile", param);


/**
 * 品牌分类/分类-初始化设置
 * @param
 */
 export const initQuality = (param) => fetch("GET", `/quality/qualityClassification/init`, param);

/**
 * 超时预警-批量设置备注
 * @param
 */
 export const warnBatchRemark = (param) => fetch("GET", `/sys/sysInfo/batchRemark`, param);

/**
 * 设置-爆量设置值
 * @param
 */
 export const setWarningScore = (param) => fetch("GET", `/customerService/deploy/setWarningScore`, param);

/**
 * 获取-爆量设置值
 * @param
 */
 export const getWarningScore = (param) => fetch("GET", `/customerService/deploy/getWarningScore`, param);

/**
 * 客服质检-汇总弹窗
 * @param
 */
 export const serTestSum = (param) => fetch("GET", `/quality/qualityRecord/getReportList`, param);

/**
 * 客服列表-汇总弹窗-客服总在线数
 * @param
 */
 export const serOnlineSum = (param) => fetch("GET", `/customerService/service/getReportList`, param);

/**
 * 预警标记已读，添加备注，上传文件接口
 * @param
 */
export const updateEarlyWarn = (param) => fetch("GET", `/sys/sysInfo/updateEarlyWarning`, param);

/**
 * 通过聊天记录ids查询聊天记录
 * @param
 */
export const chatHistory = (param) => fetch("GET", `/sys/sysInfo/getChatHisByIds`, param);

/**
 * 质检预警
 * @param
 */
export const qualityWarn = (param) => fetch("GET", `/sys/sysInfo/getEarlyWarning`, param);

/**
 * 质检申诉-通过/拒绝
 * @param
 */
export const examineAppeal = (param) => fetch("POST", `/appeal/qualityRecordAppeal/examine`, param);

/**
 * 质检申诉-作废
 * @param
 */
export const discardAppeal = (param) => fetch("GET", `/appeal/qualityRecordAppeal/discard`, param);

/**
 * 质检申诉-申诉列表
 * @param
 */
export const appealList = (param) => fetch("GET", `/appeal/qualityRecordAppeal/list`, param);

/**
 * 质检申诉-客服发起
 * @param
 */
export const qualityAppeal = (param) => fetch("POST", `/appeal/qualityRecordAppeal/appeal`, param);

/**
 * 质检记录 - 新
 * @param
 */
export const qualityRecordList = (param) => fetch("GET", `/quality/qualityRecord/qualityHistoryList`, param);

/**
 * 质检历史记录下拉 -- 质检问题分类-通过父级id查询列表
 * @param
 */
export const getListByParentId = (param) => fetch("GET", `/quality/qualityClassification/getListByParentId`, param);

/**
 * 质检设置 -- 获取质检通过分数
 * @param
 */
export const getScore = (param) => fetch("GET", `/quality/qualityRecord/getQualityScore`, param);

/**
 * 质检设置 -- 设置质检通过分数
 * @param
 */
export const setScore = (param) => fetch("GET", `/quality/qualityRecord/setQualityScore`, param);

/**
 * 客服质检 -- 历史记录 -- 列表
 * @param
 */
export const qualityHisList = (param) => fetch("GET", `/quality/qualityRecord/qualityHis`, param);

/**
 * 质检分类 -- 新增
 * @param
 */
export const deleteQuality = (param, routeParam) => fetch("POST", `/quality/qualityClassification/delete/${routeParam}`, param);

/**
 * 质检分类 -- 新增
 * @param
 */
export const addQuality = (param) => fetch("POST", `/quality/qualityClassification/save`, param);

/**
 * 质检分类 -- 编辑
 * @param
 */
export const editQuality = (param) => fetch("POST", `/quality/qualityClassification/update`, param);

/**
 * 质检分类 -- 列表
 * @param
 */
export const testSortList = (param) => fetch("GET", `/quality/qualityClassification/list`, param);

/**
 * 客服排班--批量排班
 * @param
 */
export const uploadScheduling = (param) => fetch("upload", `/customerService/scheduling/schedulingUpload`, param);

/**
 * 客服质检 -- 列表
 * @param
 */
export const serviceTestList = (param) => fetch("GET", `/quality/qualityRecord/qualityShopList`, param);

/**
 * 客服排班--批量排班2
 * @param
 */
export const serScheAll2 = (param) => fetch("GET", `/customerService/scheduling/batchArrange2`, param);

/**
 * 客服排班--批量排班
 * @param
 */
export const serScheAll = (param) => fetch("POST", `/customerService/scheduling/batchArrange`, param);

/**
 * 客服排班
 * @param
 */
export const serviceScheduling = (param) => fetch("GET", `/customerService/scheduling/arrange`, param);

/**
 * 获取班次列表
 * @param
 */
export const schedulingList = (param) => fetch("GET", `/customerService/scheduling/list`, param);

/**
 * 获取班次列表
 * @param
 */
export const frequencyList = (param) => fetch("GET", `/customerService/frequency/list`, param);

/**
 * 新增班次
 * @param
 */
export const frequencySave = (param) => fetch("POST", `/customerService/frequency/save`, param);

/**
 * 编辑班次
 * @param
 */
export const frequencyUpdate = (param) => fetch("POST", `/customerService/frequency/update`, param);

/**
 * 新增班次
 * @param
 */
export const frequencyDetail = (routeParam) => fetch("GET", `/customerService/frequency/${routeParam}`);

/**
 * 新增班次
 * @param
 */
export const frequencyDelete = (routeParam) => fetch("POST", `/customerService/frequency/delete/${routeParam}`);

/**
 * 公司列表
 * @param
 */
export const companyList = () => fetch("GET", `/company/list`);

/**
 * 部门列表
 * @param
 */
export const departList = (routeParam) => fetch("GET", `/depart/list?id=${routeParam}`);

/**
 * 客服列表
 * @param
 */
export const serviceList = (param) => fetch("GET", `/customerService/service/list`, param);

/**
 * 带店店铺列表查询
 * @param
 */
export const shopList = (param) => fetch("GET", `/customerService/service/shopList`, param);

/**
 * 设置带店
 * @param
 */
export const setShop = (param) => fetch("POST", `/customerService/service/setShop`, param);

/**
 * 客服关联的子账号查询
 * @param
 */
export const accountRelation = (param) => fetch("GET", `/customerService/service/relation`, param);

/**
 * 子账号聊天关系查询-第一层
 * @param
 */
export const relationList = (param) => fetch("GET", `/quality/qualityRecord/qualityRelationList`, param);

/**
 * 子账号聊天关系查询-第2层
 * @param
 */
export const qualityByAccount = (param) => fetch("GET", `/quality/qualityRecord/qualityByAccount`, param);

/**
 * 子账号聊天关系查询-第3层
 * @param
 */
export const qualityChatHis = (param) => fetch("GET", `/quality/qualityRecord/qualityChatHis`, param);


/**
 * 质检评审标注
 * @param
 */
export const qualityReview = (routeParam) => fetch("POST", `/quality/qualityRecord/quality`, routeParam);

/**
 * 质检历史记录列表查询
 * @param
 */
export const qualityHis = (param) => fetch("GET", `/quality/qualityRecord/qualityHis`, param);

/**
 * 质检历史详情查询
 * @param
 */
export const qualityHisDetail = (param) => fetch("GET", `/quality/qualityRecord/qualityHisDetail`, param);

/**
 * 质检问题分类-全数据列表
 * @param
 */
export const qualityList = (param) => fetch("GET", `/quality/qualityClassification/list`, param);

/**
 * 通过店铺获取客服下拉列表
 * @param
 */
export const cusList = (param) => fetch("GET", `/quality/qualityRecord/getQualityShopService`, param);

/**
 * 新建问答
 * @param
 */
export const addKnowledge = (param) => fetch("POST", `/quality/qualityRecord/addKnowledge`, param);

/**
 * 质检报表
 * @param
 */
export const qualityRecord = (param) => fetch("GET", "/quality/qualityRecord/qualityRecord", param);

/**
 * 自动情绪检测列表
 * @param
 */
export const badEmotionList = (param) => fetch("GET", "/chatAi/chatAiEmotion/badEmotionList", param);

/**
 * 自动情绪已读未读
 * @param
 */
export const readEmotion = (param) => fetch("GET", "/chatAi/chatAiEmotion/readEmotion", param);

/**
 * 客服列表-等级下拉
 * @parama
*/
export const serLevelSelect = param => fetch("GET", "/ums/umsServiceLevel/listSelect", param);

/**
 * 客服列表-设置客服等级
 * @parama
*/
export const setSerLevel = param => fetch("GET", "/ums/umsServiceLevel/distribution", param);

/**
 * 客服列表-批量设置客服等级
 * @parama
*/
export const moreSetSerLevel = param => fetch("POST", "/ums/umsServiceLevel/batch", param);

/**
 * 获取交班记录列表
 * @param
 */
export const recordList = (param) => fetch("GET", "/shifts/changeShifts/getRecordList", param);

/**
 * 获取交班记录详情
 * @param
 */
export const recordListDetail = (param) => fetch("GET", "/shifts/changeShifts/getRecordDetail", param);

/**
 * 获取交班记录列表
 * @param
 */
export const selectShopProblemList = (param) => fetch("POST", "/cms/shopProblem/selectShopProblemList", param);

/**
 * 获取交班记录列表
 * @param
 */
export const selectShopProblemDetail = (param) => fetch("GET", "/cms/shopProblem/selectShopProblemDetail", param);

/**
 * 获取交班记录列表
 * @param
 */
export const insertShopProblem = (param) => fetch("POST", "/cms/shopProblem/insertShopProblem", param);
